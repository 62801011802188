import moment from 'moment-timezone';
import qs from 'qs';
import { call, delay, put, takeLatest } from 'redux-saga/effects';

import { alertDelayError, name as appName } from '../../config';
import { JsonResult, UUID } from '../../types';
import { IRecord } from '../../types/entries';
import { getSentimentByScore } from '../../utils/records';
import { Action } from '../index';
import { fetchSaga } from './auth';
import { FetchResponse, cancelableLocationSaga, defaultResponseProcessing } from './common';

/**
 * Constants
 * */
export const moduleName = 'records';
const prefix = `${appName}/${moduleName}`;

export const RECORDS_START = `${prefix}/RECORDS_START`;
export const RECORDS_SUCCESS = `${prefix}/RECORDS_SUCCESS`;
export const RECORDS_ERROR = `${prefix}/RECORDS_ERROR`;
export const RECORDS_ERROR_RESET = `${prefix}/RECORDS_ERROR_RESET`;

export const GET_RECORDS = `${prefix}/GET_RECORDS`;
export const GET_RECORD_DETAILS = `${prefix}/GET_RECORD_DETAILS`;
export const DELETE_RECORD = `${prefix}/DELETE_RECORD`;

/**
 * Reducer
 * */
export interface State {
  loading: boolean;
  error: Error | null;
  records: IRecord[];
  record: IRecord | null | undefined;
  recordsTotal: number;
}

const localState: State = {
  loading: false,
  error: null,
  records: [],
  record: undefined,
  recordsTotal: 0,
};

export default function reducer(state = localState, action: Action = { type: 'undefined' }): State {
  const { type, payload } = action;

  switch (type) {
    case RECORDS_START:
      return { ...state, loading: true, error: null, record: undefined };
    case RECORDS_SUCCESS:
      return { ...state, loading: false, ...payload };
    case RECORDS_ERROR:
      return { ...state, loading: false, error: payload };
    case RECORDS_ERROR_RESET:
      return { ...state, loading: false, error: null };

    default:
      return state;
  }
}

/**
 * Interfaces
 * */

/**
 * Action Creators
 * */

export type IParamsKeyType = 'page' | 'size' | 'sort' | 'order';

export interface IParams {
  page?: number;
  size?: number;
  sort?: string | null;
  order?: string | null;
}

export const getRecordsAction = (organisationUuid: UUID, params?: IParams, assistant_id?: string): Action => ({
  type: GET_RECORDS,
  payload: { organisationUuid, params, assistant_id },
});

export interface IGetRecord {
  uuid: UUID;
  characterUuid: UUID;
}

export interface IDeleteRecord {
  id: string;
  characterId: string;
}

export const getRecord = (payload: IGetRecord): Action => ({
  type: GET_RECORD_DETAILS,
  payload,
});

export const deleteRecord = (payload: IDeleteRecord): Action => ({
  type: DELETE_RECORD,
  payload,
});

/**
 * Sagas
 */
export function* getRecordsSaga({
  payload,
}: {
  payload: { organisationUuid: UUID; assistant_id: string; params?: IParams };
}): Generator {
  yield put({
    type: RECORDS_START,
  });

  const { organisationUuid, assistant_id, params = {} } = payload;

  Object.entries(params).forEach(([key, value]) => {
    if (!value) delete params[key as IParamsKeyType];
  });

  const url = `${process.env.REACT_APP_TRANSCRIPTS_API_URL}${
    assistant_id ? `/assistant/${assistant_id}/transcripts` : `/organisation/${organisationUuid}/transcripts`
  }?${qs.stringify(params)}`;

  const response = (yield call(fetchSaga, url)) as FetchResponse;

  yield defaultResponseProcessing(response, RECORDS_SUCCESS, RECORDS_ERROR, false, (data) => ({
    records: data.transcripts.map((item: JsonResult) => ({
      id: item._id,
      uuid: item.id,
      key: item.id,
      date: moment(item.createdAt),
      duration: item.durationSeconds,
      handler: {
        id: item.assistantId,
        name: item.character_name,
      },
      sentiment: getSentimentByScore(item.analysis.sentimentScore),
      audio: item.stereoRecordingUrl,
      messages: item.messages
        .filter((message: JsonResult) => message.role === 'bot' || message.role === 'user')
        .map((message: JsonResult, index: number) => ({
          id: index,
          role: message.role,
          content: message.message,
        })),
      summary: item.summary,
      notes: '',
    })),
    recordsTotal: data.total,
  }));
}

export function* getRecordSaga({ payload: { uuid, characterUuid } }: { payload: IGetRecord }): Generator {
  yield put({
    type: RECORDS_START,
  });

  const response = (yield call(
    fetchSaga,
    `${process.env.REACT_APP_TRANSCRIPTS_API_URL}/assistant/${characterUuid}/call-details/${uuid}`,
  )) as FetchResponse;

  yield defaultResponseProcessing(response, RECORDS_SUCCESS, RECORDS_ERROR, false, (item) => ({
    record: {
      id: item.id,
      date: moment(item.datetime),
      duration: item.duration,
      customerData: item.customer_data,
      sentiment: item.sentiment,
      audio: item.recording_url,
      messages: item.transcript
        .filter((message: JsonResult) => message.role === 'bot' || message.role === 'user')
        .map((message: JsonResult, index: number) => ({
          id: index,
          role: message.role,
          content: message.message,
        })),
      summary: item.summary,
      notes: '',
    },
  }));
}

export function* deleteRecordSaga({ payload: { id, characterId } }: { payload: IDeleteRecord }): Generator {
  yield put({
    type: RECORDS_START,
  });

  const response = (yield call(
    fetchSaga,
    `${process.env.REACT_APP_TRANSCRIPTS_API_URL}/assistant/${characterId}/transcript/${id}`,
    {
      method: 'DELETE',
    },
  )) as FetchResponse;

  yield defaultResponseProcessing(response, RECORDS_SUCCESS, RECORDS_ERROR, false, () => ({
    record: null,
  }));
}

export function* saga(): Generator {
  yield takeLatest(GET_RECORDS, cancelableLocationSaga.bind(null, getRecordsSaga, RECORDS_ERROR, false));
  yield takeLatest(GET_RECORD_DETAILS, cancelableLocationSaga.bind(null, getRecordSaga, RECORDS_ERROR, false));
  yield takeLatest(DELETE_RECORD, cancelableLocationSaga.bind(null, deleteRecordSaga, RECORDS_ERROR, false));
  yield takeLatest(RECORDS_ERROR, function* errorReset() {
    yield delay(alertDelayError);
    yield put({
      type: RECORDS_ERROR_RESET,
    });
  });
}
