import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Typography } from 'antd';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ICharacter } from '../../../../../types/entries';
import ContentCard from '../../../../Common/ContentCard';
import styles from './index.module.less';

interface ICharacterTile {
  character: ICharacter;
}

const CharacterTile: React.FC<ICharacterTile> = ({ character }) => {
  const navigate = useNavigate();

  const { uuid, name, isActive, purpose, successCount, rating, emptyCount, totalCount } = character;

  const navigateCharacterPage = useCallback(() => {
    navigate(`/characters/${uuid}`);
  }, [navigate]);

  return (
    <ContentCard
      gap="1rem"
      borderRadius="1rem"
      align="center"
      background={!isActive ? 'var(--color-card-background-inactive)' : undefined}
    >
      {/* TODO commented for current iteration */}
      {/* <div className="w-full flex-row flex-justify-end"> */}
      {/*  <Switch defaultChecked={isActive} onChange={handleChangeCharacterStatus} /> */}
      {/* </div> */}
      <div className="flex-column gap-8 flex-align-center">
        <Avatar className="border-1 border-color-primary" size={100} icon={<UserOutlined />} src={character.image} />
        <Typography.Title className={clsx('color-primary fs-24 m-0', { 'color-stroke': !isActive })} level={2}>
          {name}
        </Typography.Title>
      </div>
      <Typography className={clsx('fs-20', { 'color-stroke': !isActive })}>{purpose}</Typography>

      <div className={clsx(styles.container, { [styles.containerInactive]: !isActive })}>
        <div className={styles.statsBox}>
          <Typography className={styles.title}>Empty Calls</Typography>
          <Typography className={styles.value}>{emptyCount}</Typography>
        </div>
        <div className={styles.statsBox}>
          <Typography className={styles.title}>Calls Handled</Typography>
          <Typography className={styles.value}>{successCount}</Typography>
        </div>
        <div className={styles.statsBox}>
          <Typography className={styles.title}>Total Calls</Typography>
          <Typography className={styles.value}>{totalCount}</Typography>
        </div>
        <div className={styles.statsBox}>
          <Typography className={styles.title}>% Positive</Typography>
          <Typography
            className={clsx(styles.value, {
              'color-green': isActive && rating >= 90,
              'color-blue': isActive && rating < 90 && rating >= 50,
              'color-red': isActive && rating < 50,
            })}
          >
            {rating}
          </Typography>
        </div>
      </div>
      <Button onClick={navigateCharacterPage}>Manage</Button>
    </ContentCard>
  );
};

export default CharacterTile;
