import { JsonResult, UUID } from './index';

export type UserRoleType = 'admin' | 'customer';
export enum UserRoleEnum {
  admin = 'admin',
  customer = 'customer',
}

export interface IOrganisation {
  active_subscription: boolean;
  comments: string | null;
  created_at: string;
  email_address: string;
  id: number;
  is_active: boolean;
  is_deleted: boolean;
  name: string;
  stripe_id: string | null;
  updated_at: string;
  uuid: UUID;
  web_address: string | null;
}

export interface IUser {
  id: number;
  uuid: UUID;
  created_at: string;
  updated_at: string;
  is_deleted: boolean;
  is_active: boolean;
  comments: string | null;
  email: string;
  first_name: string;
  last_name: string;
  organisation?: IOrganisation;
  organisation_id: number;
  password: string;
  is_verified: boolean;
  role?: UserRoleType;
  avatar?: string;
}

export interface ICharacter {
  uuid: string;
  image: string;
  name: string;
  isActive: boolean;
  purpose: string;
  callsHandled: number;
  emptyCount: number;
  successCount: number;
  totalCount: number;
  rating: number;
  activationId: string;
}

export interface ICharacterDetails {
  activation_id: string;
  avatar_url: string;
  booking_url: string;
  description: string;
  first_message: string;
  is_active: boolean;
  locale: string;
  model_id: number;
  name: string;
  organisation_id: number;
  retrieval_augmented_generation_uuid: UUID;
  system_prompt: string;
  uuid: UUID;
  voice_id: string;
}

export interface ICharacterUpdate {
  assistant_uuid: UUID;
  name?: string;
  image_data?: string;
  first_message?: string;
  system_prompt?: string;
  voice_id?: string;
}

export interface ICharacterPerformance {
  assistant_uuid: string;
  assistant_avatar_url: string;
  assistant_name: string;
  assistant_purpose: string | null;
  percentPositive: number;
  emptyCount: number;
  successCount: number;
  totalCount: number;
}

export interface ICharactersStats {
  callsHandled: number;
  callsDuration: number;
  sentimental: {
    positive: number;
    neutral: number;
    negative: number;
  };
}

export interface IMessage {
  id: string;
  role: string;
  content: string;
}

export type StatusesType = 'negative' | 'neutral' | 'positive';

export interface IKeyword {
  label: string;
  status: StatusesType;
}

export interface IKeywords {
  intro?: IKeyword[];
  decisionMaker?: IKeyword[];
  nextSteps?: IKeyword[];
}

export interface IRecord {
  id: string;
  date: Date;
  duration: number;
  handler: {
    id: string;
    name: string;
  };
  customerData?: JsonResult;
  sentiment: 'string';
  audio: string;
  messages?: IMessage[];
  summary?: string;
  notes?: string;
  keywords?: IKeywords;
}

export interface IVoice {
  id: string;
  name: string;
  gender: string;
  age: string;
  accent: string;
}
