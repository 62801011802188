import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CallIcon from '@mui/icons-material/Call';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import moment from 'moment-timezone';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';

import { moduleName as authModuleName } from '../../../../store/ducks/auth';
import { moduleName as charactersModuleName } from '../../../../store/ducks/characters';
import { moduleName as customerModuleName, getCustomerPeriodAction } from '../../../../store/ducks/customer';
import { RootState } from '../../../../store/reducers';
import { UUID } from '../../../../types';
import { IPeriod } from '../../../../types/customer';
import { ICharactersStats, IUser } from '../../../../types/entries';
import AnalyticsTile from '../../../Common/Analytics/AnalyticsTile';
import OverallSentiment from '../../../Common/Analytics/OverallSentiment';
import LatestRecordings from '../../../Common/LatestRecordings';
import PageWrapper from '../../../Common/PageWrapper';

interface IDashboard {
  characterLoading: boolean;
  customerLoading: boolean;
  stats: ICharactersStats | null;
  user: IUser | null;
  customerPeriod: IPeriod | null;
  getCustomerPeriod: (organisationUuid: UUID) => void;
}

const Dashboard: React.FC<IDashboard> = ({
  characterLoading,
  customerLoading,
  stats,
  user,
  customerPeriod,
  getCustomerPeriod,
}) => {
  useEffect(() => {
    if (!user?.organisation?.uuid) {
      return;
    }

    getCustomerPeriod(user.organisation.uuid);
  }, [user]);

  const { rMins, rSecs } = customerPeriod ?? {};

  const getRemainingTime = () => {
    if (!rMins && !rSecs) return 0;

    if (rMins && rMins >= 1) return rMins.toLocaleString();

    if (rSecs && rSecs < 10) return `00:0${rSecs}`;

    return `00:${rSecs}`;
  };

  const getRemainingTimeLabel = () => {
    if (rMins === 1) return 'Minute Remaining';

    if (!rMins && rSecs) return 'Seconds Remaining';

    return `Minutes Remaining`;
  };

  return (
    <PageWrapper
      loading={characterLoading || customerLoading}
      extraColumn={{
        width: 120,
        align: 'center',
        dataIndex: 'uuid',
        renderText: (uuid) => (
          <Link to={`/characters/${uuid}`} className="text-decoration-none">
            View Details
          </Link>
        ),
      }}
      header={
        <div className="flex-column gap-16">
          <div className="grid grid-cols-3 gap-16">
            <AnalyticsTile
              value={stats?.callsHandled ?? 0}
              label="Total Calls"
              icon={<CallIcon className="color-primary fs-40" />}
            />
            <AnalyticsTile
              value={moment.utc((stats?.callsDuration || 0) * 1000).format('mm:ss')}
              label="Average calls duration"
              icon={<TimelapseIcon className="color-primary fs-40" />}
            />
            <AnalyticsTile
              value={getRemainingTime()}
              label={getRemainingTimeLabel()}
              icon={<AccessTimeIcon className="color-primary fs-40" />}
            />
          </div>
          <OverallSentiment title="Overall Sentiment" sentiment={stats?.sentimental} />
        </div>
      }
    >
      <LatestRecordings />
    </PageWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  characterLoading: state[charactersModuleName].loading,
  customerLoading: state[customerModuleName].loading,
  stats: state[charactersModuleName].stats,
  user: state[authModuleName].user,
  customerPeriod: state[customerModuleName].period,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  getCustomerPeriod: (organisationUuid: UUID) => dispatch(getCustomerPeriodAction(organisationUuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
