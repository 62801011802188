import { call, delay, put, select, takeLatest } from 'redux-saga/effects';

import { alertDelayError, name as appName } from '../../config';
import { UUID } from '../../types';
import {
  ICharacter,
  ICharacterDetails,
  ICharacterPerformance,
  ICharacterUpdate,
  ICharactersStats,
  IVoice,
} from '../../types/entries';
import { imageUrlToBase64 } from '../../utils';
import { VOICES_LIST } from '../constants';
import { Action } from '../index';
import { RootState } from '../reducers';
import { fetchSaga } from './auth';
import { FetchResponse, cancelableLocationSaga, defaultResponseProcessing } from './common';

/**
 * Constants
 * */
export const moduleName = 'characters';
const prefix = `${appName}/${moduleName}`;

export const CHARACTERS_START = `${prefix}/CHARACTERS_START`;
export const CHARACTERS_SUCCESS = `${prefix}/CHARACTERS_SUCCESS`;
export const CHARACTERS_ERROR = `${prefix}/CHARACTERS_ERROR`;
export const CHARACTERS_ERROR_RESET = `${prefix}/CHARACTERS_ERROR_RESET`;
export const VOICES_SUCCESS = `${prefix}/VOICES_SUCCESS`;

export const GET_CHARACTER = `${prefix}/GET_CHARACTER`;
export const GET_CHARACTERS = `${prefix}/GET_CHARACTERS`;
export const GET_CHARACTER_VOICES = `${prefix}/GET_CHARACTER_VOICES`;
export const CREATE_CHARACTER = `${prefix}/CREATE_CHARACTER`;
export const CHECK_IF_CHARACTER_CAN_BE_CREATED = `${prefix}/CHECK_IF_CHARACTER_CAN_BE_CREATED`;
export const UPDATE_CHARACTER = `${prefix}/UPDATE_CHARACTER`;

/**
 * Reducer
 * */
export interface State {
  loading: boolean;
  characterLoading: boolean;
  error: Error | null;
  charactersList: ICharacter[] | null;
  charactersTotal: number;
  character: ICharacterDetails | null;
  stats: ICharactersStats | null;
  isCharacterCanBeCreated: boolean;
  voices: IVoice[];
}

const localState: State = {
  loading: true,
  characterLoading: false,
  error: null,
  charactersList: null,
  character: null,
  charactersTotal: 0,
  stats: null,
  isCharacterCanBeCreated: true,
  voices: [],
};

export default function reducer(state = localState, action: Action = { type: 'undefined' }): State {
  const { type, payload } = action;

  switch (type) {
    case CHARACTERS_START:
      return { ...state, loading: true, error: null, ...payload };
    case CHARACTERS_SUCCESS:
      return { ...state, loading: false, ...payload };
    case CHARACTERS_ERROR:
      return { ...state, loading: false, characterLoading: false, error: payload };
    case CHARACTERS_ERROR_RESET:
      return { ...state, loading: false, characterLoading: false, error: null };
    case VOICES_SUCCESS:
      return { ...state, ...payload };

    default:
      return state;
  }
}

/**
 * Interfaces
 * */

/**
 * Action Creators
 * */

export interface IParams {
  id?: string;
  page?: number;
  size?: number;
}

export interface ICreateCharacterParams {
  name: string;
  image_data: string;
  elevenlabs_voice_id: string;
  organisationUuid: UUID;
  callback?: () => void;
}

export interface ICheckIfCharacterCanBeCreatedAction {
  organisationUuid: UUID;
}

export interface IChangeCharacterStatusParams {
  id: string;
  isActive: boolean;
}

export const getCharactersAction = (organisationUuid: UUID, params?: IParams, assistantUuid?: UUID): Action => ({
  type: GET_CHARACTERS,
  payload: {
    assistantUuid,
    params,
    organisationUuid,
  },
});

export const getCharacterAction = (assistantUuid: UUID): Action => ({
  type: GET_CHARACTER,
  payload: {
    assistantUuid,
  },
});

export const updateCharacterAction = (body: ICharacterUpdate): Action => ({
  type: UPDATE_CHARACTER,
  payload: {
    body,
  },
});

export const createCharacterAction = (payload: ICreateCharacterParams): Action => ({
  type: CREATE_CHARACTER,
  payload,
});

export const checkIfCharacterCanBeCreatedAction = (payload: ICheckIfCharacterCanBeCreatedAction): Action => ({
  type: CHECK_IF_CHARACTER_CAN_BE_CREATED,
  payload,
});

export const getVoicesAction = (): Action => ({
  type: GET_CHARACTER_VOICES,
});

/**
 * Sagas
 */
export function* getCharacterSaga({ payload: { assistantUuid } }: { payload: { assistantUuid: UUID } }): Generator {
  yield put({
    type: CHARACTERS_START,
    payload: { characterLoading: true },
  });
  const url = `${process.env.REACT_APP_BILLING_API_URL}/assistants/get_assistant?assistant_uuid=${assistantUuid}`;

  const response = (yield call(fetchSaga, url)) as FetchResponse;

  yield defaultResponseProcessing(response, CHARACTERS_SUCCESS, CHARACTERS_ERROR, false, (character) => ({
    character,
    characterLoading: false,
  }));
}

export function* getCharactersSaga({
  payload: { assistantUuid, organisationUuid },
}: {
  payload: { assistantUuid?: UUID; organisationUuid: UUID };
}): Generator {
  const { charactersList } = yield select((state: RootState) => state[moduleName]);

  yield put({
    type: CHARACTERS_START,
    payload: {
      charactersList: assistantUuid ? charactersList.filter((item: ICharacter) => item.uuid === assistantUuid) : null,
    },
  });

  const url = assistantUuid
    ? `${process.env.REACT_APP_TRANSCRIPTS_API_URL}/assistant/${assistantUuid}/assistant-details`
    : `${process.env.REACT_APP_TRANSCRIPTS_API_URL}/organisation/${organisationUuid}/assistant-details`;

  const response = (yield call(fetchSaga, url)) as FetchResponse;

  yield defaultResponseProcessing(response, CHARACTERS_SUCCESS, CHARACTERS_ERROR, false, (data) => {
    const characters =
      data.character_performances && Array.isArray(data.character_performances) ? data.character_performances : [data];

    return {
      charactersList: characters.map((character: ICharacterPerformance) => ({
        uuid: character.assistant_uuid,
        key: character.assistant_uuid,
        image: character.assistant_avatar_url,
        name: character.assistant_name,
        purpose: character.assistant_purpose,
        isActive: true,
        rating: character.percentPositive,
        emptyCount: character.emptyCount,
        successCount: character.successCount,
        totalCount: character.totalCount,
      })),
      stats: {
        callsHandled: data.total_successful_calls,
        callsDuration: data.call_dur_avg,
        sentimental: {
          positive: data.pieChart.pos,
          neutral: data.pieChart.neu,
          negative: data.pieChart.neg,
        },
      },
      charactersTotal: data.length,
    };
  });
}

export function* createCharacterSaga({ payload }: { payload: ICreateCharacterParams }): Generator {
  const { callback, organisationUuid, ...params } = payload;

  yield put({
    type: CHARACTERS_START,
    payload: {
      characterLoading: true,
    },
  });

  const body = {
    organisation_uuid: organisationUuid,
    ...params,
  };

  if (!body.image_data) {
    const voice = VOICES_LIST.find((voiceItem) => voiceItem.id === body.elevenlabs_voice_id);

    if (voice) {
      body.image_data = yield call(imageUrlToBase64, `/default_${voice.gender.toLowerCase()}.png`);
    }
  }

  const response = yield call(
    fetchSaga,
    `${process.env.REACT_APP_BILLING_API_URL}/assistants/create_assistant`,
    {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    },
    {},
  );

  yield defaultResponseProcessing(response, CHARACTERS_SUCCESS, CHARACTERS_ERROR, false, () => {
    if (callback) {
      callback();
    }

    return {
      characterLoading: false,
      charactersList: null,
    };
  });
}

export function* updateCharacterSaga({ payload: { body } }: { payload: { body: ICharacterUpdate } }): Generator {
  yield put({
    type: CHARACTERS_START,
  });

  const response = yield call(
    fetchSaga,
    `${process.env.REACT_APP_BILLING_API_URL}/assistants/update_assistant`,
    {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    },
    {},
  );

  const { character } = yield select((state: RootState) => state[moduleName]);

  yield defaultResponseProcessing(response, CHARACTERS_SUCCESS, CHARACTERS_ERROR, false, (data) => {
    const updated_data = { ...data };

    delete updated_data.assistantUuid;

    return {
      ...character,
      ...updated_data,
    };
  });
}

export function* checkIfCharacterCanBeCreatedSage({
  payload: { organisationUuid },
}: {
  payload: ICheckIfCharacterCanBeCreatedAction;
}): Generator {
  yield put({
    type: CHARACTERS_START,
  });

  const response = yield call(
    fetchSaga,
    `${process.env.REACT_APP_BILLING_API_URL}/organisation/${organisationUuid}/can-create-assistant`,
  );

  yield defaultResponseProcessing(response, CHARACTERS_SUCCESS, CHARACTERS_ERROR, false, (isCharacterCanBeCreated) => ({
    isCharacterCanBeCreated,
  }));
}

export function* getVoicesSaga() {
  yield put({
    type: VOICES_SUCCESS,
    payload: { voices: VOICES_LIST },
  });
}

export function* saga(): Generator {
  yield takeLatest(GET_CHARACTER, cancelableLocationSaga.bind(null, getCharacterSaga, CHARACTERS_ERROR, false));
  yield takeLatest(GET_CHARACTERS, cancelableLocationSaga.bind(null, getCharactersSaga, CHARACTERS_ERROR, false));
  yield takeLatest(CREATE_CHARACTER, cancelableLocationSaga.bind(null, createCharacterSaga, CHARACTERS_ERROR, false));
  yield takeLatest(
    CHECK_IF_CHARACTER_CAN_BE_CREATED,
    cancelableLocationSaga.bind(null, checkIfCharacterCanBeCreatedSage, CHARACTERS_ERROR, false),
  );
  yield takeLatest(UPDATE_CHARACTER, cancelableLocationSaga.bind(null, updateCharacterSaga, CHARACTERS_ERROR, false));
  yield takeLatest(GET_CHARACTER_VOICES, cancelableLocationSaga.bind(null, getVoicesSaga, CHARACTERS_ERROR, false));
  yield takeLatest(CHARACTERS_ERROR, function* errorReset() {
    yield delay(alertDelayError);
    yield put({
      type: CHARACTERS_ERROR_RESET,
    });
  });
}
