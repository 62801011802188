import { IPlan } from '../types';
import { IVoice } from '../types/entries';

export const CUSTOMER_ID = process.env.REACT_APP_CUSTOMER_ID;

export const PLANS: IPlan[] = [
  {
    id: '8d2dccd5-58b0-4a39-b50f-412a21de6d89',
    name: 'Lite',
    description: 'Basic AI voice features ideal for startup web and phone use',
    price: 50,
    currency: 'gbp',
    period: 'month',
    features: [
      {
        label: '200 Neural Voice minutes',
        isEnabled: true,
      },
      {
        label: 'Neural Knowledge base',
        isEnabled: true,
      },
      {
        label: 'Neural Voice management dashboard',
        isEnabled: true,
      },
      {
        label: 'Web integration',
        isEnabled: true,
      },
      {
        label: 'CRM integration',
        isEnabled: false,
      },
      {
        label: 'Outbound Phone Calls',
        isEnabled: false,
      },
      {
        label: 'Automated workflows and triggers',
        isEnabled: false,
      },
      {
        label: '2-way CRM Data Injection',
        isEnabled: false,
      },
    ],
  },
  {
    id: 'cda5e9a0-4858-4c74-b942-1affee0871a7',
    name: 'Standard',
    description: 'Basic AI voice features ideal for startup web and phone use',
    price: 400,
    currency: 'gbp',
    period: 'month',
    features: [
      {
        label: '1,000 Neural Voice minutes',
        isEnabled: true,
      },
      {
        label: 'Neural Knowledge base',
        isEnabled: true,
      },
      {
        label: 'Neural Voice management dashboard',
        isEnabled: true,
      },
      {
        label: 'Web and Phone',
        isEnabled: true,
      },
      {
        label: 'CRM integration',
        isEnabled: false,
      },
      {
        label: 'Outbound Phone Calls',
        isEnabled: false,
      },
      {
        label: 'Automated workflows and triggers',
        isEnabled: false,
      },
      {
        label: '2-way CRM Data Injection',
        isEnabled: false,
      },
    ],
  },
  {
    id: '8255dd54-314b-400a-ba9f-6ce00e5ea2ed',
    name: 'Professional',
    description: 'Expanded AI integration and features for involving business needs',
    price: 1650,
    currency: 'gbp',
    period: 'month',
    features: [
      {
        label: '5,000 Neural Voice minutes',
        isEnabled: true,
      },
      {
        label: 'Neural Knowledge base',
        isEnabled: true,
      },
      {
        label: 'Neural Voice management dashboard',
        isEnabled: true,
      },
      {
        label: 'Web and Phone',
        isEnabled: true,
      },
      {
        label: 'CRM integration',
        isEnabled: true,
      },
      {
        label: 'Outbound Phone Calls',
        isEnabled: true,
      },
      {
        label: 'Automated workflows and triggers',
        isEnabled: false,
      },
      {
        label: '2-way CRM Data Injection',
        isEnabled: false,
      },
    ],
    isActive: true,
  },
  {
    id: '404a959d-c184-4d52-8023-8960ab83b095',
    name: 'Enterprise',
    description: 'Comprehensive AI solution with full support for large enterprises',
    price: 3200,
    currency: 'gbp',
    period: 'month',
    features: [
      {
        label: '10,000 Neural Voice minutes',
        isEnabled: true,
      },
      {
        label: 'Neural Knowledge base',
        isEnabled: true,
      },
      {
        label: 'Neural Voice management dashboard',
        isEnabled: true,
      },
      {
        label: 'Web and Phone',
        isEnabled: true,
      },
      {
        label: 'CRM integration',
        isEnabled: true,
      },
      {
        label: 'Outbound Phone Calls',
        isEnabled: true,
      },
      {
        label: 'Automated workflows and triggers',
        isEnabled: true,
      },
      {
        label: '2-way CRM Data Injection',
        isEnabled: true,
      },
    ],
  },
];

export const VOICES_LIST: IVoice[] = [
  {
    id: 'lcMyyd2HUfFzxdCaC4Ta',
    name: 'Sarah',
    gender: 'Female',
    age: 'Middle Aged',
    accent: 'British',
  },
  {
    id: '19STyYD15bswVz51nqLf',
    name: 'Ana',
    gender: 'Female',
    age: 'Young Adult',
    accent: 'British',
  },
  {
    id: 'Se2Vw1WbHmGbBbyWTuu4',
    name: 'Amelia',
    gender: 'Female',
    age: 'Young Adult',
    accent: 'British',
  },
  {
    id: 'ZF6FPAbjXT4488VcRRnw',
    name: 'Daniel',
    gender: 'Male',
    age: 'Middle Aged',
    accent: 'British',
  },
  {
    id: 'kmSVBPu7loj4ayNinwWM',
    name: 'Archie',
    gender: 'Male',
    age: 'Young Adult',
    accent: 'British',
  },
  {
    id: 'IKne3meq5aSn9XLyUdCD',
    name: 'Joe',
    gender: 'Male',
    age: 'Middle Aged',
    accent: 'British',
  },
  {
    id: '9BWtsMINqrJLrRacOk9x',
    name: 'Ella',
    gender: 'Female',
    age: 'Young',
    accent: 'American',
  },
  {
    id: 'Ky9j3wxFbp3dSAdrkOEv',
    name: 'Eleanor',
    gender: 'Female',
    age: 'Old',
    accent: 'British',
  },
  {
    id: 'ThT5KcBeYPX3keUQqHPh',
    name: 'Betty',
    gender: 'Female',
    age: 'Middle',
    accent: 'British',
  },
  {
    id: 'PxiDzTegwYFeWabJ8mEd',
    name: 'Judith',
    gender: 'Female',
    age: 'Middle',
    accent: 'British',
  },
  {
    id: 'jsCqWAovK2LkecY7zXl4',
    name: 'Addie',
    gender: 'Female',
    age: 'Young',
    accent: 'British',
  },
  {
    id: 'L4so9SudEsIYzE9j4qlR',
    name: 'Angela',
    gender: 'Female',
    age: 'Old',
    accent: 'British',
  },
  {
    id: 'BNgbHR0DNeZixGQVzloa',
    name: 'Alexander',
    gender: 'Male',
    age: 'Old',
    accent: 'British',
  },
  {
    id: 'Tx7VLgfksXHVnoY6jDGU',
    name: 'Justin',
    gender: 'Male',
    age: 'Young',
    accent: 'British',
  },
  {
    id: 'onwK4e9ZLuTAKqWW03F9',
    name: 'Robert',
    gender: 'Male',
    age: 'Old',
    accent: 'British',
  },
  {
    id: 'CZ1JCWXlwX5dmHx0XdiL',
    name: 'Neil',
    gender: 'Male',
    age: 'Young',
    accent: 'British',
  },
  {
    id: '70e4PKMb7PwQ98Hi1lBo',
    name: 'Jeremy',
    gender: 'Male',
    age: 'Middle',
    accent: 'British',
  },
  {
    id: 'dVoNx1XahVTpMFlwOciu',
    name: 'Gideon',
    gender: 'Male',
    age: 'Old',
    accent: 'British',
  },
  {
    id: 'aTbnroHRGIomiKpqAQR8',
    name: 'Emily',
    gender: 'Female',
    age: 'Middle Age',
    accent: 'British',
  },
  {
    id: 'aD6riP1btT197c6dACmy',
    name: 'Sophie',
    gender: 'Female',
    age: 'Young Adult',
    accent: 'British',
  },
  {
    id: 'wo6udizrrtpIxWGp2qJk',
    name: 'Terry',
    gender: 'Male',
    age: 'Middle Age',
    accent: 'British',
  },
  {
    id: 'VR6AewLTigWG4xSOukaG',
    name: 'James',
    gender: 'Male',
    age: 'Young Adult',
    accent: 'British',
  },
  {
    id: 'AXdMgz6evoL7OPd7eU12',
    name: 'Rebecca',
    gender: 'Female',
    age: 'Middle Age',
    accent: 'British',
  },
  {
    id: '1yiyKQi9kAGTjsuBuSvt',
    name: 'William',
    gender: 'Male',
    age: 'Middle Age',
    accent: 'British',
  },
  {
    id: 'KJF2ogTBPpTHWHAqRZrS',
    name: 'Callum',
    gender: 'Male',
    age: 'Young Adult',
    accent: 'British',
  },
  {
    id: 'kBag1HOZlaVBH7ICPE8x',
    name: 'Alex',
    gender: 'Female',
    age: 'Young Adult',
    accent: 'British',
  },
  {
    id: 'VG7zjqAT7O4FXCR57Wwv',
    name: 'Leo',
    gender: 'Male',
    age: 'Young Adult',
    accent: 'British',
  },
  {
    id: 'nPczCjzI2devNBz1zQrb',
    name: 'Edward',
    gender: 'Male',
    age: 'Middle Age',
    accent: 'Welsh',
  },
  {
    id: 's7VgmkAoDwT6l2zXqEPV',
    name: 'Daniel',
    gender: 'Male',
    age: 'Middle Age',
    accent: 'British',
  },
  {
    id: '3XOBzXhnDY98yeWQ3GdM',
    name: 'Hunter',
    gender: 'Male',
    age: 'Young Adult',
    accent: 'American',
  },
  {
    id: 'XH7KR8MDn5xIMYpbfUTx',
    name: 'Holly',
    gender: 'Female',
    age: 'Young Adult',
    accent: 'British',
  },
  {
    id: 'iP95p4xoKVk53GoZ742B',
    name: 'George',
    gender: 'Male',
    age: 'Middle Age',
    accent: 'British',
  },
  {
    id: '2EiwWnXFnvU5JabPnv8n',
    name: 'Frederick',
    gender: 'Male',
    age: 'Old',
    accent: 'British',
  },
  {
    id: 'pqHfZKP75CvOlQylNhV4',
    name: 'Terry',
    gender: 'Male',
    age: 'Middle Age',
    accent: 'British',
  },
  {
    id: 'rCmVtv8cYU60uhlsOo1M',
    name: 'Grace',
    gender: 'Female',
    age: 'Middle Age',
    accent: 'British',
  },
  {
    id: 'Xb7hH8MSUJpSbSDYk0k2',
    name: 'Charlotte',
    gender: 'Female',
    age: 'Young Adult',
    accent: 'British',
  },
  {
    id: 'aEO01A4wXwd1O8GPgGlF',
    name: 'Bethany',
    gender: 'Female',
    age: 'Middle Age',
    accent: 'British',
  },
  {
    id: 'cgSgspJ2msm6clMCkdW9',
    name: 'Laura',
    gender: 'Female',
    age: 'Middle Age',
    accent: 'British',
  },
  {
    id: '4CrZuIW9am7gYAxgo2Af',
    name: 'Pippa',
    gender: 'Female',
    age: 'Young Adult',
    accent: 'British',
  },
  {
    id: 'FA6HhUjVbervLw2rNl8M',
    name: 'Maria',
    gender: 'Female',
    age: 'Middle Age',
    accent: 'British',
  },
  {
    id: 'u8mYrrobJUV7LGGK3Ks5',
    name: 'Richard',
    gender: 'Male',
    age: 'Middle Age',
    accent: 'British',
  },
];
